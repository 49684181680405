<template>
  <a-layout class="index animated fadeIn">
    <a-card class="mb10">
      <a-button size="small" @click="
          () => {
            $router.go(-1);
          }
        ">
        <LeftOutlined />返回
      </a-button>
      <span class="ml15 card_title"> {{ query.type ? (query.type == "detail" ? "" : "修改") : "添加" }}管理员</span>
    </a-card>
    <a-card>
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="account" label="用户账号" name="account" help="账号只能由字母、数字、下划线组成">
          <a-input v-model:value="form.account" :readonly="query.type && query.type == 'detail'" placeholder="请输入用户账号"
            v-inputFilter:sn />
        </a-form-item>
        <a-form-item ref="username" label="用户姓名" name="username">
          <a-input v-model:value="form.username" :readonly="query.type && query.type == 'detail'"
            placeholder="请输入用户姓名" />
        </a-form-item>
        <a-form-item ref="password" label="用户密码" name="password" v-if="!query.type" help="默认密码:123456">
          <a-input v-model:value="form.password" type="password" />
        </a-form-item>
        <a-form-item ref="role_ids" label="用户角色" name="role_ids">
          <a-select mode="multiple" :disabled="query.type && query.type == 'detail'" v-model:value="form.role_ids"
            style="width: 100%" placeholder="请选择">
            <a-select-option v-for="v in roles" :key="v.id" :value="v.id" :label="v.username">
              {{ v.role_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item ref="phone" label="联系电话" name="phone">
          <a-input v-model:value="form.phone" :readonly="query.type && query.type == 'detail'" placeholder="请输入用联系电话" />
        </a-form-item>
        <a-form-item ref="address" label="联系地址" name="address">
          <a-input v-model:value="form.address" :readonly="query.type && query.type == 'detail'"
            placeholder="请输入联系地址" />
        </a-form-item>
        <a-form-item ref="remark" label="用户说明" name="remark">
          <a-input v-model:value="form.remark" :readonly="query.type && query.type == 'detail'" placeholder="请输入用户说明" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 8, offset: 2 }" v-if="query.type !== 'detail'">
          <a-button type="primary" @click="onSubmit"> 保存 </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </a-layout>
</template>

<script>
  import { LeftOutlined } from "@ant-design/icons-vue";
  import { getRole, createAdmin, adminDetail, editAdmin } from "@/api/system";
  import { ref, onMounted, reactive, toRefs, inject } from "vue";
  import { $iscode } from "@/utils/app";
  import { message } from 'ant-design-vue';
  // 初始化默认筛选项数值
  let defForm = {
    account: "",
    username: "",
    password: "123456",
    role_ids: [],
    id: null,
    phone: "",
    address: "",
    remark: "",
  };
  export default {
    components: {
      LeftOutlined,
    },
    setup() {
      //pass
      const form = ref({ ...defForm });
      const state = reactive({
        labelCol: { span: 2 },
        wrapperCol: { span: 8 },
        value: [],
        rules: {
          account: [
            {
              required: true,
              message: "请输入账号（账号只能由字母、数字、下划线组成）",
            },
          ],
          role_ids: [
            {
              required: true,
              message: "请选择权限组",
            },
          ],
          password: [
            {
              required: true,
              message: "请输入密码",
            },
          ],
          username: [
            {
              required: true,
              message: "请输入用户账号",
            },
          ],
        },
        roles: [],
      });
      const $router = inject("$router");
      const $route = inject("$route");
      const _lodash = inject("_lodash");
      onMounted(async () => {
        getRoles();
        if (!_lodash.isEmpty($route.query)) {
          // 获取详情
          const hide = message.loading('正在加载中...', 0);
          let result = await adminDetail($route.query.id).then((res) => res.data).catch((error) => error);
          if ($iscode(result)) {
            result.data.role_ids = result.data.role_ids.map(v => Number(v))
            form.value = result.data;
          } else {
            message.error(result.msg);
          }
          setTimeout(hide);
        }
      });

      // 获取权限组
      const getRoles = async () => {
        let result = await getRole().then((res) => res.data).catch((error) => error);
        state.roles = result.data.data;
      };

      const ruleForm = ref();
      const onSubmit = () => {
        ruleForm.value.validate().then(async () => {
            const hide = message.loading('正在加载中...', 0);
            let result;
            if ($route.query.type !== "edit") {
              result = await createAdmin({ ...form.value })
                .then((res) => res.data)
                .catch((error) => error);
            } else {
              result = await editAdmin($route.query.id, { ...form.value })
                .then((res) => res.data)
                .catch((error) => error);
            }
            if ($iscode(result, true)) {
              ruleForm.value.resetFields();
              $router.go(-1);
            }
            setTimeout(hide);
          }).catch((error) => {
            message.error("请完善表单");
          });
      };
      return {
        form,
        ...toRefs(state),
        ruleForm,
        onSubmit,
        query: $route.query,
      };
    },
  };
</script>